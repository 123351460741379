/* Move down content because we have a fixed navbar that is 50px tall */
body {
	padding-top: 65px;
	padding-bottom: 20px;
}

/* Wrapping element */
/* Set some basic padding to keep content from hitting the edges */
.body-content {
	padding-left: 5px;
	padding-right: 5px;
}

/* bottom status bar */
.bottom-status-bar {
	background-color: #333;
	/* overflow: hidden; */
	position: fixed;
	z-index: 100;
	left: 0;
	bottom: 0;
	width: 100%;
	color: white;
	padding: 3px;
}

/* auto scroll modal body */
.modal-body {
	max-height: calc(100vh - 220px);
	overflow-y: auto;
}
 
/* overlay used to hide dialog controls until loading has completed */
.modal-body-overlay {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	background-color: white;
}

html {
	font-size: 16px;
}

.page-control-bar {
	position: fixed;
	top: 50px;
	width: 100%;
	background-color: powderblue;
	background-color: white;
	z-index: 999;
}

.page-control-bar + .body-content {
	padding-top: 22px;
}


.simple-browser {
	position: fixed;
	top: 55px;
	width: 100%;
	background-color: white;
	z-index: 999;
}

.simple-browser-button {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
}

.browser-left-btn {
	position: fixed;
	left: 10px;
	z-index: 999;
}

.browser-right-btn {
	position: fixed;
	right: 10px;
	z-index: 999;
}


/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 54px;
	height: 28px;
}

/* Hide default HTML checkbox */
.switch input {
	display: none;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 20px;
	width: 20px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked + .slider {
	background-color: #2196F3;
}

input:focus + .slider {
	box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 28px;
}

.slider.round:before {
	border-radius: 50%;
}
